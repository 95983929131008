<template>
  <div class="text-left">
    <BaseHeader :title="'Balance Payments'"></BaseHeader>

    <BalanceComponent />
   
  </div>
</template>

<script>
import BalanceComponent from "../../components/payments/BalanceComponent.vue";
export default {
    components: { BalanceComponent }
};
</script>
